import React from "react"
import { Text, Heading, Flex, Box } from "rebass"
import { useThemeUI } from "theme-ui"
import Container from "../_layout/container"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const FeatureHeading = ({
  title,
  subtitle,
  color = "text",
  backgroundColor = "transparent",
  px,
  py = 10,
  pb,
}) => {
  const { theme } = useThemeUI()

  const [ref, inView] = useInView({
    threshold: 0.2,
  })

  return (
    <Container backgroundColor={backgroundColor} px={px} py={py} pb={pb}>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        ref={ref}
      >
        <Text
          fontSize={16}
          fontWeight={300}
          color={color}
          sx={{
            ":before": {
              content: "'[ '",
              color: theme.colors.primary,
              fontWeight: 800,
            },
            ":after": {
              content: "' ]'",
              color: theme.colors.primary,
              fontWeight: 800,
            },
          }}
          mb={[6]}
        >
          {subtitle}
        </Text>

        <Box sx={{ overflow: "hidden" }}>
          <motion.div
            style={{ position: "relative" }}
            initial={{ top: 120 }}
            animate={{ top: inView ? 0 : 120 }}
            transition={{
              duration: 0.8,
              ease: "easeOut",
            }}
          >
            <Heading variant="mainHeading" color={color}>
              {title}
            </Heading>
          </motion.div>
        </Box>
      </Flex>
    </Container>
  )
}

export default FeatureHeading
