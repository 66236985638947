import React from "react"
import { useThemeUI } from "theme-ui"
import { Box } from "rebass"
import { keyframes } from "@emotion/core"

const Scroller = () => {
  const { theme } = useThemeUI()

  const scrollMouse = keyframes`
    0% {
      opacity: 1;
      transform: translate3d(0,0,0);
    }
    70% {
      opacity: 1;
      transform: translate3d(0,15px,0);
    }
    70% {
      opacity: 0;
      transform: translate3d(0,15px,0);
    }
    100% {
      opacity: 0;
      transform: translate3d(0,0,0);
    }
  `

  return (
    <Box
      sx={{
        width: 35,
        height: 50,
        position: "relative",
        border: `1px solid ${theme.colors.background}`,
        borderRadius: "1em",
        ":before": {
          content: "''",
          width: 6,
          height: 8,
          margin: "0 auto",
          position: "absolute",
          background: `${theme.colors.background}`,
          left: 0,
          right: 0,
          top: "10px",
          borderRadius: 8,
          animation: `${scrollMouse} 2s ease infinite`,
        },
      }}
    ></Box>
  )
}

export default Scroller
