import React from "react"
import { Flex, Box } from "rebass"
import GraphCard from "../../../../components/tab-card"
import _ from "lodash"
import Container from "../../../../components/_layout/container"
import Clients from "../../../../components/clients"

const Tabs = () => {
  const selectedTab = 1

  const cardData = [
    {
      id: 1,
      description:
        "With a long-standing experience in the field, we constantly deliver large-scale performance and extreme quality results to our partners",

      bullets: ["Drive to website", "Lead generation", "Customer generation"],
      numbers: [
        {
          n: "130k",
          label: "leads/month",
        },
        {
          n: "8.5m",
          label: "clicks/month",
        },
      ],
    },
    // {
    //   id: 2,
    //   description:
    //     "Our in-house team of full-stack developers allows Spinup to have just one limit to the approach to any issue and opportunity: imagination",
    //   bullets: ["Full stack development", "CRM and tools", "Mobile apps"],
    //   numbers: [
    //     {
    //       n: "6",
    //       label: "softwares",
    //     },
    //     {
    //       n: "4",
    //       label: "patents",
    //     },
    //   ],
    // },
    // {
    //   id: 3,
    //   description:
    //     "We work closely on a daily basis with the biggest traffic sources. We know their gotchas and we have flows, technologies and people ready to solve them",
    //   bullets: ["Media buying", "Advertising 2.0", "Performance"],
    //   numbers: [
    //     {
    //       n: "+450k",
    //       label: "€ budget marketing/month",
    //     },
    //     {
    //       n: "70m",
    //       label: "paid impressions/month",
    //     },
    //   ],
    // },
  ]

  return (
    <Container backgroundColor="orange" size="small" px={[5, 6]}>
      {/* <Flex
        mx={-2}
        my={[5, 6]}
        sx={{ borderBottom: `1px solid ${theme.colors.primary}` }}
      >
        {_.map(buttons, (button, index) => (
          <Box
            width={[1 / 3]}
            ml={index === 0 ? 0 : 2}
            key={`tab-button-${button.id}`}
            onClick={() => setSelectedTab(button.id)}
            sx={{
              backgroundColor: "text",
              cursor: "pointer",
              marginBottom: button.id === selectedTab ? "-1.5px" : 0,
              border:
                button.id === selectedTab
                  ? `1px solid ${theme.colors.primary}`
                  : "1px solid transparent",
              borderBottomColor: "transparent",
              fontSize: 0,
              fontWeight: 700,
              borderRadius: 0,
              color: "background",
              textAlign: "center",
              py: 3,
            }}
          >
            {button.label}
          </Box>
        ))}
      </Flex> */}

      <Flex flexWrap="wrap" mx={-2}>
        <Box width={[1]} p={3} key={selectedTab}>
          <GraphCard
            backgroundColor="#fd6f3b"
            description={_.find(cardData, ["id", selectedTab]).description}
            bullets={_.find(cardData, ["id", selectedTab]).bullets}
            numbers={_.find(cardData, ["id", selectedTab]).numbers}
          />
        </Box>
      </Flex>
      <Clients />
    </Container>
  )
}

export default Tabs
