import React from "react"
import { Flex, Box, Image } from "rebass"

const Clients = ({ mt = [4, 6, 9], px = [5, 6, 10], mb = [3, 6, 8] }) => {
  return (
    <Flex
      mt={mt}
      px={px}
      mb={mb}
      flexDirection="row"
      alignItems="center"
      flexWrap={["wrap"]}
      justifyContent="space-around"
    >
      {[
        "vodafone",
        "tim",
        "windtre",
        "tiscali",
        "linkem",
        "prima-assicurazioni",
        "sigla-credit",
        "quale",
        "banca-sistema",
        "cef",
        "sky",
        "opel",
        "fincontinuo",
        "metlife",
        "pegaso",
        "signorprestito",
      ].map((client, i) => (
        <Box
          width={[1 / 3, 1 / 3, 1 / 6]}
          key={i}
          sx={{
            textAlign: "center",
            flexShrink: 0,
          }}
          mb={[3]}
        >
          <Image
            src={`https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/clients/${client}.png`}
            alt={client}
            sx={{
              height: [30],
              filter: "brightness(0) invert(1)",
            }}
          />
        </Box>
      ))}
    </Flex>
  )
}

export default Clients
