import React from "react"
import { Flex, Text, Box, Heading } from "rebass"
import { useThemeUI } from "theme-ui"
import Container from "../_layout/container"
import _ from "lodash"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const Numbers = () => {
  const { theme } = useThemeUI()

  const [ref, inView] = useInView({
    threshold: 0.2,
  })

  const metrics = [
    {
      id: 1,
      label: "Generated leads / month",
      value: "130k",
    },
    {
      id: 2,
      label: "Paid impressions / month",
      value: "110mln",
    },
    {
      id: 3,
      label: "Delivered paid clicks / month",
      value: "8.5mln",
    },
    {
      id: 4,
      label: "CAGR year over year",
      value: "154%",
    },
  ]

  return (
    <Container backgroundColor="primary" size="large">
      <Flex
        py={[5, 6, 10]}
        width="100%"
        color="background"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ textAlign: "center" }}
      >
        <Box sx={{ overflow: "hidden" }} ref={ref}>
          <motion.div
            style={{ position: "relative" }}
            initial={{ top: 80 }}
            animate={{ top: inView ? 0 : 80 }}
            transition={{
              duration: 0.8,
              ease: "easeOut",
            }}
          >
            <Heading fontSize={[3, 4]} mb={[4, 7, 8]}>
              Our numbers
            </Heading>
          </motion.div>
        </Box>

        <Flex flexWrap="wrap" width="100%">
          {_.map(metrics, metric => (
            <Box
              key={`metric-${metric.id}`}
              width={["50%", "25%"]}
              mb={[2, 7]}
              sx={
                {
                  /* ":before": {
                  content: "''",
                  display: "block",
                  height: "8px",
                  width: "60px",
                  backgroundColor: theme.colors.white,
                }, */
                }
              }
            >
              <Text
                as="h2"
                fontWeight={theme.fontWeights.heading}
                fontSize={[2, 3, "74px"]}
              >
                {metric.value}
              </Text>
              <Text fontWeight={theme.fontWeights.light} fontSize={[0, 1]}>
                {metric.label}
              </Text>
            </Box>
          ))}
        </Flex>
      </Flex>
    </Container>
  )
}

export default Numbers
